import { useStaticQuery, graphql } from 'gatsby';

const useCsrPartnersPage = () => {
    const { sanityPageCsrPartners: PageCsrPartners } = useStaticQuery(graphql`
        query {
            sanityPageCsrPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                csrPartnersMain {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
                csrPartners {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
            }
        }
    `);

    return PageCsrPartners;
};

export default useCsrPartnersPage;
